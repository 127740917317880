<template>
  <app-modal
    :title="$t('hp.info_modal_header')"
    @close="close"
  >
    <template slot="body">
      <pre>{{ item.params }}</pre>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/shared/Modal'

export default {
  name: 'InfoModal',
  props: {
    item: {
      type: Object
    }
  },
  components: {
    appModal: Modal
  },
  data () {
    return {
      dataLoaded: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
